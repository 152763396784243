<template>
    <!-- <div> -->
    <div class="vx-col md:w-1/1 w-full">
        <!-- main page -->
        <vx-card title="" style="">
            <vs-tabs ref="sub-tabs" alignment="center">
                <!-- <vs-tab label="New">
                    <div class="con-tab-ejemplo">
                        <datatable :progress="'new'" :progressRestriction="true" :territory="territory" :salesChannel="salesChannel" :customerCategory="customerCategory" :salesPersonal="salesPersonal" :doFilter="doFilter" v-on:clickView="clickView" :SelectedOrderTypes="SelectedOrderTypes"></datatable>
                    </div>
                </vs-tab> -->
                <vs-tab label="All">
                    <div class="con-tab-ejemplo">
                        <datatable :progress="'all'" :userPermission="userPermission" :dateStart="dateStart" :dateEnd="dateEnd" :progressRestriction="true" :territory="territory" :salesChannel="salesChannel" :customerCategory="customerCategory" :salesPersonal="salesPersonal" :doFilter="doFilter" :sources="sources" v-on:clickView="clickView" v-on:handleFilters="handleFilters" :SelectedOrderTypes="SelectedOrderTypes"></datatable>
                    </div>
                </vs-tab>
                <vs-tab label="Hold">
                    <div class="con-tab-ejemplo">
                        <datatable :progress="'hold'" :userPermission="userPermission" :dateStart="dateStart" :dateEnd="dateEnd" :progressRestriction="false" :territory="territory" :salesChannel="salesChannel" :customerCategory="customerCategory" :salesPersonal="salesPersonal" :doFilter="doFilter" :sources="sources" v-on:clickView="clickView" v-on:selectSalesOrder="updateSelectedSalesOrder" v-on:handleFilters="handleFilters" :SelectedOrderTypes="SelectedOrderTypes" v-on:setAsNewSo="setAsNewSo"></datatable>
                    </div>
                </vs-tab>
                <vs-tab label="Waiting Approval">
                    <div class="con-tab-ejemplo">
                        <datatable :progress="'waiting_approval'" :userPermission="userPermission" :dateStart="dateStart" :dateEnd="dateEnd" :progressRestriction="true" :territory="territory" :salesChannel="salesChannel" :customerCategory="customerCategory" :salesPersonal="salesPersonal" :doFilter="doFilter" :sources="sources" v-on:clickView="clickView" v-on:handleFilters="handleFilters" :SelectedOrderTypes="SelectedOrderTypes"></datatable>
                    </div>
                </vs-tab>
                <!-- <vs-tab label="CBD">
                    <div class="con-tab-ejemplo">
                        <datatable :progress="'cbd'" :dateStart="dateStart" :dateEnd="dateEnd" :progressRestriction="true" :territory="territory" :salesChannel="salesChannel" :customerCategory="customerCategory" :salesPersonal="salesPersonal" :doFilter="doFilter" v-on:clickView="clickView" :SelectedOrderTypes="SelectedOrderTypes"></datatable>
                    </div>
                </vs-tab>
                <vs-tab label="B2B">
                    <div class="con-tab-ejemplo">
                        <datatable :progress="'b2b'" :dateStart="dateStart" :dateEnd="dateEnd" :progressRestriction="true" :territory="territory" :salesChannel="salesChannel" :customerCategory="customerCategory" :salesPersonal="salesPersonal" :doFilter="doFilter" v-on:clickView="clickView" :SelectedOrderTypes="SelectedOrderTypes"></datatable>
                    </div>
                </vs-tab> -->
                <!-- <vs-tab label="Released-SO">
                    <div class="con-tab-ejemplo">
                        <datatable :progress="'released'" :dateStart="dateStart" :dateEnd="dateEnd" :progressRestriction="false" :territory="territory" :salesChannel="salesChannel" :customerCategory="customerCategory" :salesPersonal="salesPersonal" :doFilter="doFilter" v-on:clickView="clickView" :SelectedOrderTypes="SelectedOrderTypes"></datatable>
                    </div>
                </vs-tab> -->
                <!-- By Ilmi -->
                <!-- Ini untuk Manual Allocation -->
                <vs-tab label="Draft-PI">
                    <div class="con-tab-ejemplo">
                        <datatable :progress="'manual'" :dateStart="dateStart" :dateEnd="dateEnd" :progressRestriction="true" :territory="territory" :salesChannel="salesChannel" :customerCategory="customerCategory" :salesPersonal="salesPersonal" :doFilter="doFilter" :sources="sources" v-on:clickManualAllocation="clickManualAllocation" v-on:clickView="clickView" v-on:handleFilters="handleFilters" :SelectedOrderTypes="SelectedOrderTypes" v-on:setAsNewSo="setAsNewSo"></datatable>
                    </div>
                </vs-tab>
                <vs-tab label="Released-PI">
                    <div class="con-tab-ejemplo">
                        <datatable :progress="'allocated'" :userPermission="userPermission" :dateStart="dateStart" :dateEnd="dateEnd" :progressRestriction="true" :territory="territory" :salesChannel="salesChannel" :customerCategory="customerCategory" :salesPersonal="salesPersonal" :doFilter="doFilter" :sources="sources" v-on:clickView="clickView" v-on:handleFilters="handleFilters" :SelectedOrderTypes="SelectedOrderTypes"></datatable>
                    </div>
                </vs-tab>
                <vs-tab label="Rejected">
                    <div class="con-tab-ejemplo">
                        <datatable :progress="'rejected'" :userPermission="userPermission" :dateStart="dateStart" :dateEnd="dateEnd" :progressRestriction="true" :territory="territory" :salesChannel="salesChannel" :customerCategory="customerCategory" :salesPersonal="salesPersonal" :doFilter="doFilter" :sources="sources" v-on:clickView="clickView" v-on:handleFilters="handleFilters" :SelectedOrderTypes="SelectedOrderTypes"></datatable>
                    </div>
                </vs-tab>
                <vs-tab label="Canceled">
                    <div class="con-tab-ejemplo">
                        <datatable :progress="'cancel'" :userPermission="userPermission" :dateStart="dateStart" :dateEnd="dateEnd" :progressRestriction="false" :territory="territory" :salesChannel="salesChannel" :customerCategory="customerCategory" :salesPersonal="salesPersonal" :doFilter="doFilter" :sources="sources" v-on:clickView="clickView" v-on:handleFilters="handleFilters" :SelectedOrderTypes="SelectedOrderTypes"></datatable>
                    </div>
                </vs-tab>
            </vs-tabs>
        </vx-card>
    </div>
</template>


<script>
// import detail from "../component/detail_selected_so.vue";
import datatable from "./datatable.vue";

export default {
    components: {
        // detail,
        datatable
    },
    props:
        ["territory", "salesChannel", "customerCategory", "salesPersonal", "doFilter","sources","dateStart","dateEnd","SelectedOrderTypes", "userPermission"],
        data() {
            return {
                //
            };
        },
    watch: {
        doFilter: function() {
            console.log("this.territory", this.territory)
            console.log("this.salesChannel", this.salesChannel)
            console.log("this.customerCategory", this.customerCategory)
            console.log("this.salesPersonal", this.salesPersonal)
            console.log("this.doFilter", this.doFilter)
        },
    },
    mounted() {
        console.log("this.territory", this.territory)
        console.log("this.salesChannel", this.salesChannel)
        console.log("this.customerCategory", this.customerCategory)
        console.log("this.salesPersonal", this.salesPersonal)
        console.log("this.doFilter", this.doFilter)
        console.log("this.source", this.sources)
        console.log("this.userPermission", this.userPermission)
        // this.$store.dispatch("user/getPermissions","sales-order");
        if ( this.userPermission == undefined ) {
            this.$store.dispatch("user/getPermissions","sales-order");
            console.log("this.$store", this.$store)
            console.log("this.$store.getters['user/hasPermissions']('view')", this.$store.getters['user/hasPermissions']('view'))
            this.userPermission = {
                view: this.$store.getters['user/hasPermissions']('view'),
                edit: this.$store.getters['user/hasPermissions']('edit'),
                create: this.$store.getters['user/hasPermissions']('create'),
                delete: this.$store.getters['user/hasPermissions']('delete'),
                cancel: this.$store.getters['user/hasPermissions']('cancel'),
            }
            console.log("this.userPermission", this.userPermission)
        }
    },
    computed: {
        //
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },

        clickView(salesOrder, progress) {
            this.$emit("selectSalesOrder", salesOrder);
            this.$emit("redirectTab", 3, 'monitoring', progress);
        },
        clickManualAllocation(salesOrder, progress) {
            this.$emit("selectSalesOrder", salesOrder);
            this.$emit("redirectTab", 4, 'monitoring', progress);
        },
        getOptionTerritory() {
            this.$http.get("/api/v1/master/multiple-territory").then(resp => {
                this.optionTerritory = resp.data.records;
                this.territory = resp.data.records[0];
            });
        },
        getOptionSalesChannel() {
            // this.$http.get("/api/v1/sales-channel/personal").then(
            this.$http.get("/api/v1/sales-channel/list").then(
                resp => {
                    // console.log(resp)
                    if(resp.code == 200){
                        this.optionSalesChannel = []
                        this.optionSalesChannel.push({code:'All'})
                        for(var salesChannel = 0;salesChannel<resp.data.length;salesChannel++){
                            this.optionSalesChannel.push(resp.data[salesChannel])
                        }
                        this.salesChannel = this.optionSalesChannel[0];
                        this.AlloptionSalesChannel = this.optionSalesChannel;
                    }else{
                        // this.$vs.loading.close();
                        this.$router.push("/");
                    }
                }
            );
        },
        getOptionCustomerCategory() {
            this.$http.get("/api/v1/master/customer-category-all").then(
                resp => {
                    if(resp.code == 200){
                        this.optionCustomerCategory = []
                        this.optionCustomerCategory.push({name:'All'})
                        for(var customerCategory = 0;customerCategory<resp.data.length;customerCategory++){
                            this.optionCustomerCategory.push(resp.data[customerCategory])
                        }
                        this.customerCategory = this.optionCustomerCategory[0];
                        this.AlloptionCustomerCategory = this.optionCustomerCategory;
                    }else{
                        // this.$vs.loading.close();
                        this.$router.push("/");
                    }
                }
            );
        },
        getOptionSalesID(is_default) {
            console.log(this.selectedSO.Customer)
            console.log(is_default)
            let superadmin, territory_id, sales_channel_id, sales_personal_id;
            this.$userLogin.roles.filter(function(item) {
                if(item.Name == "superadmin"){
                  superadmin = true;
                }else{
                  superadmin = false;
                }
            });
            this.isLoadingSales = true
            territory_id = (this.selectedTerritory != null && this.selectedTerritory.id) ? this.selectedTerritory.id : 0;
            sales_channel_id = this.selectedSalesChannel != null ? this.selectedSalesChannel[0].ID : 0;
            sales_personal_id = this.selectedSO.SalesPersonalID ? this.selectedSO.SalesPersonalID : 0;
            this.$http.get("/api/v1/sales-order/personals",{
                params: {
                    search: this.searchSales,
                    territory_id: territory_id,
                    sales_channel_id: sales_channel_id,
                    sales_personal_id: sales_personal_id,
                }
              }).then(
              resp => {
                this.isLoadingSales = false
                if(resp.code == 200){
                    this.salesPersonal.option = []
                    this.salesPersonal.all_options = []
                    let data = resp.data.salesman
                    for(var idx = 0;idx<data.length;idx++){
                        this.salesPersonal.option.push(data[idx])
                        this.salesPersonal.all_options.push(data[idx])
                    }
                    if (!superadmin) {
                        // set default Sales ID jika bukan superadmin
                        this.salesPersonal.selected = this.salesPersonal.option.filter((v) => {
                            return v.ID == this.$userLogin.id;
                        });
                    }
                    if(is_default && sales_personal_id != 0){
                        // set default Sales ID saat dipanggil dari selectedCust
                        this.salesPersonal.selected = this.salesPersonal.option[0];
                        // set sales channel
                        this.salesChannel.selected = this.salesChannel.all_options.filter((v) => {
                            return v.ID == this.salesPersonal.option[0].SalesChannelId;
                        });
                        this.salesChannel.selected = this.salesChannel.selected[0];
                    }
                } else {
                    console.log(resp)
                }
              }
            );
        },
        updateSelectedSalesOrder(salesOrder, progress) {
            console.log("progress", progress)
            this.$emit("selectSalesOrder", salesOrder);
            this.$emit("redirectTab", 3, '', '');
        },
        handleFilters(){
            this.$emit("handleFilters");
        },
        setAsNewSo(salesOrder, progress){
            console.log("salesOrder", salesOrder)
            console.log("progress", progress)
            this.$emit("setAsNewSo", salesOrder);
        }
    }
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
